import React from "react"
import { Container } from "react-bootstrap"

export default function Mei() {
    return (
        <Container className="mt-2">
            <a
                className="link-offset-2 link-underline link-underline-opacity-0 mei"
                href="https://meiwengy.me"
                target="_blank"
                rel="noreferrer"
            >
                Mei's Trash Tech ✨
            </a>
        </Container>
    )
}
