import { Button } from "react-bootstrap"
import { exampleQuestions } from "../data/exampleQuestions"
import { useState, useEffect } from "react"

interface ExampleQuestionsProps {
    onQuestionClick: (question: string) => void
}

const ExampleQuestions: React.FC<ExampleQuestionsProps> = ({
    onQuestionClick,
}) => {
    const [displayQuestions, setDisplayQuestions] = useState<string[]>([])

    useEffect(() => {
        const randomQuestions = []
        const exampleQuestionsCopy = [...exampleQuestions]
        for (let i = 0; i < 3; i++) {
            const randomIndex = Math.floor(
                Math.random() * exampleQuestionsCopy.length
            )
            randomQuestions.push(exampleQuestionsCopy[randomIndex])
            exampleQuestionsCopy.splice(randomIndex, 1)
        }
        setDisplayQuestions(randomQuestions)
    }, [])

    return (
        <div>
            {displayQuestions.map((question, index) => (
                <span>
                    <Button
                        variant="link"
                        key={index}
                        onClick={() => onQuestionClick(question)}
                    >
                        {question}
                    </Button>
                    <br />
                </span>
            ))}
        </div>
    )
}

export default ExampleQuestions
