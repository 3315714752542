export const exampleQuestions = [
    "What is single-cell analysis enable scientists to study individual cells in their normal environment?",
    "What are the benefits and challenges of using CRISPR for gene editing?",
    "Tell me about the universal flu vaccine target parts of the virus that are less variable than the ones used in current vaccines?",
    "What are some of the applications and implications of quantum computing, which is expected to reach a quantum advantage in the next decade?",
    "How does synthetic biology allow researchers to create new forms of life and redesign existing ones?",
    "How does brain-computer interface technology enable direct communication between the brain and external devices?",
    "How does nanomedicine use tiny particles to diagnose and treat diseases at the molecular level?",
    "How does bioprinting use 3D printing technology to create artificial organs and tissues?",
    "How does regenerative medicine use stem cells and other techniques to repair or replace damaged or diseased tissues and organs?",
    "How does geoengineering aim to manipulate the Earth’s climate system to mitigate the effects of global warming?",
]
