import React from "react"
// import "bootstrap/dist/css/bootstrap.min.css"
import logo from "./logo.svg"
import "./App.scss"
import DeepThinkly from "./models/deepthinkly"
import Header from "./components/header"

import { Container } from "react-bootstrap"
import Mei from "./components/mei"

function App() {
    return (
        <Container className="App">
            <Header />
            <DeepThinkly />
            <Mei />
        </Container>
    )
}

export default App
