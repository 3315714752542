export default async function onSubmitDeepThinkly(
    inputText: string,
    setResult: (arg0: { result: any; proof: any; url: any }) => void,
    setError: (arg0: string) => void
) {
    try {
        console.log("onSubmit called with inputText: " + inputText)
        const response = await fetch(
            "https://australia-southeast1-deep-thinker-a1ed5.cloudfunctions.net/google-search-ai-http",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    question: inputText,
                }),
            }
        )

        if (!response.ok) {
            throw new Error("Failed to fetch data")
        }

        const data = await response.json()

        // Assuming 'data' is in the format you provided
        const { result, proof, url } = data

        // Use setResult to contain all three elements
        setResult({ result, proof, url })
    } catch {
        // Handle errors here, you can log the error or display an error message
        console.error("Error:", "error.message")
        setError("There's been a bad :(")
        // You might also want to set an error state or display an error message to the user.
    }
}
