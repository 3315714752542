import { useState } from "react"
import onSubmitDeepThinkly from "../api/deepthinklyapi"
import { exampleQuestions } from "../data/exampleQuestions"
import ExampleQuestions from "../components/examples"

import {
    Badge,
    Button,
    Container,
    Form,
    FormControl,
    InputGroup,
    Spinner,
} from "react-bootstrap"

const extractDomainFromUrl = (url: string): string => {
    if (!url) {
        return ""
    }

    let domain = url.replace(/(https?:\/\/)?(www\d?\.)?/, "")
    domain = domain.split("/")[0]

    return domain
}

const DeepThinkly = () => {
    interface DeepThinklyState {
        inputText: string
        result: string
        loading: boolean
        proof: string
        proofUrl: string
        proofDomain: string
        exampleQuestion: string
        error: string
        showExampleQuestions: boolean
    }

    const [state, setState] = useState<DeepThinklyState>({
        inputText: "",
        result: "",
        loading: false,
        proof: "",
        proofUrl: "",
        proofDomain: "",
        exampleQuestion:
            exampleQuestions[
                Math.floor(Math.random() * exampleQuestions.length)
            ],
        error: "",
        showExampleQuestions: true,
    })

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setState({ ...state, loading: true, error: "" })
        onSubmitDeepThinkly(
            state.inputText,
            ({
                result,
                proof,
                url,
            }: {
                result: string
                proof: string
                url: string
            }) => {
                setState({
                    ...state,
                    result: result,
                    proof: proof,
                    proofUrl: url,
                    proofDomain: extractDomainFromUrl(url),
                    loading: false,
                })
            },
            (error: string) => {
                setState({ ...state, loading: false, error: error })
            }
        )
    }

    return (
        <Container>
            <div className="vow mb-3">
                I Swear to tell the truth, the Whole Truth, and nothing but the
                Truth, So Help Me God"
            </div>
            {/* form container */}
            <Form onSubmit={handleSubmit} className="mb-3">
                <Container className="form">
                    {/* handles text prompt from user */}
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="form" id="basic-addon1">
                            Question
                        </InputGroup.Text>
                        <FormControl
                            className="form"
                            placeholder={state.exampleQuestion}
                            aria-label="Question"
                            aria-describedby="basic-addon2"
                            value={state.inputText}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    inputText: e.target.value,
                                })
                            }
                        />
                    </InputGroup>
                </Container>

                <Button
                    variant="secondary"
                    size="lg"
                    type="submit"
                    disabled={state.loading}
                    className="align-self-center mt-2 thinking-deeply-button"
                >
                    {state.loading ? (
                        <span>
                            <Spinner variant="grow-sm"></Spinner>
                            <span role="status" className="ms-3">
                                Thinking deeply...
                            </span>
                        </span>
                    ) : (
                        "Ask nicely"
                    )}
                </Button>
            </Form>
            {/* result display */}
            {state.error ? (
                <Container>
                    <div className="text-start">
                        <div className="mb-3">😭 {state.error}</div>
                    </div>
                </Container>
            ) : (
                state.result && (
                    <Container>
                        <div className="text-start">
                            <div className="mb-3">{state.result}</div>
                            <div className="mb-3">
                                <blockquote>{state.proof}</blockquote>
                            </div>
                        </div>
                        <Container className="text-end mb-5">
                            <a
                                href={state.proofUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Badge bg="secondary">
                                    Source: {state.proofDomain}
                                </Badge>
                            </a>
                        </Container>
                    </Container>
                )
            )}
            <Container className="text-start">
                <div
                    onClick={() =>
                        setState({
                            ...state,
                            showExampleQuestions: !state.showExampleQuestions,
                        })
                    }
                >
                    <span style={{ cursor: "pointer" }}>
                        {state.showExampleQuestions ? "▼" : "►"} Example
                        questions
                    </span>
                </div>
                {state.showExampleQuestions && (
                    <ExampleQuestions
                        onQuestionClick={(question) =>
                            setState({ ...state, inputText: question })
                        }
                    />
                )}
            </Container>
        </Container>
    )
}

export default DeepThinkly
